<template>
  <div class="achievement">
    <template v-if="isHaveData">
      <van-dropdown-menu>
        <van-dropdown-item v-model="value" :options="option" @change="selectSem" />
      </van-dropdown-menu>
      <div class="page-cont">
        <div class="semester" v-for="(item, key, index) in onlySemList" :key="index">
          <div class="sem-header">第{{key}}学期</div>
          <div class="table-area">
            <div class="table-header pub">
              <div class="h-item items">课程名称</div>
              <div class="h-item items itemG">平时分</div>
              <div class="h-item items itemG">期末分</div>
              <div class="h-item items itemG">补考分</div>
              <div class="h-item items itemG" v-show="getShow(item)">抽考分</div>
              <div class="h-item items itemG">总分</div>
            </div>
            <div class="table-content pub" v-for="(ite, ind) in item" :key="ind">
              <div class="cont-item items">{{ite.courseName}}</div>
              <div class="cont-item items itemG">{{String(ite.usuallyScore) == 'null' ? '/' : ite.usuallyScore}}</div>
              <div class="cont-item items itemG">{{String(ite.endScore) == 'null' ? '/' : ite.endScore}}</div>
              <div class="cont-item items itemG">{{String(ite.supplementaryScore) == 'null' ? '/' : ite.supplementaryScore}}</div>
              <div class="cont-item items itemG" v-if="ite.exitsDraw==true">{{String(ite.drawScore ) == 'null' ? '/' : ite.drawScore  }}</div>
              <div class="cont-item items itemG">{{String(ite.totalScore) == 'null' ? '/' : ite.totalScore}}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <img class="noData" src="../../assets/img/noData.png" alt="">
    </template>
  </div>
</template>

<script>
import methods from './jsModule/achievement'
export default {
  name: 'achievement',
  data() {
    return {
      value: 0,
      option: [
        { text: '全部', value: 0 }
      ],
      semesterList: {},
      onlySemList: {},
      isHaveData: true,
    }
  },
  watch: {
    '$store.state.isGetToken'(val) {
      if(val) {
        this.getGradeList();
      } else {
        // 本地
        // this.getGradeList();
      }
    }
  },
  methods
}
</script>

<style lang='scss'>
  .achievement {
    height: 100%;
    background-color: #F9F9F9;
    overflow: scroll;
    overflow-x: hidden;
    .van-dropdown-menu {
      margin-top: 6px;
      .van-dropdown-menu__item {
        background-color: #F9F9F9;
      }
    }
    .page-cont {
      .semester {
        padding-bottom: 10px;
        .sem-header {
          background: #fff;
          height: 48px;
          line-height: 48px;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
        }
        .table-area {
          .table-header {
            background-color: #EFEFEF;
          }
          .table-content:nth-of-type(odd) {
            background-color: #F9F9F9;
          }
          .table-content:nth-of-type(even) {
            background-color: #fff;
          }
          .pub {
            min-height: 50px;
            display: flex;
            align-items: center;
            .items {
              flex: 2;
              text-align: center;
              font-weight: 400;
              color: #333333;
            }
            .itemG {
              flex: 1;
            }
            .h-item {
              font-size: 14px;
            }
            .cont-item {
              font-size: 12px;
            }
          }
        }
      }
    }
    .noData {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 220px;
      height: 150px;
    }
  }
</style>