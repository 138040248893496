const achievement = {
  getGradeList() {
    this.$request.getGrade().then(res => {
      if(res.data.code == 0) {
        if(Object.keys(res.data.data).length) {
          this.isHaveData = true;
          this.semesterList = res.data.data;
          this.onlySemList = res.data.data;
          for(let key in this.semesterList) {
            this.option.push({
              text: `第${key}学期`,
              value: key
            })
          }
        } else {
          this.isHaveData = false;
        }
      }
    })
  },
  selectSem(val) {
    for(let key in this.semesterList) {
      if(val == 0) {
        this.onlySemList = this.semesterList;
      } else if(val == key) {
        this.onlySemList = {};
        this.onlySemList[key] = this.semesterList[key];
      }
    }

  },
  getShow(item) {
    if (item.length>0) {
      for (let i in item) {
        if (item[i].exitsDraw==true) {
          return true
        } else {
          return false
        }
      }
    }
  }
}

export default achievement;